import { Tag } from '@troon/ui';
import { Match, Switch } from 'solid-js';

export function TroonAccessTag(props: { product: 'TROON_ACCESS' | 'TROON_ACCESS_PLUS' | null | undefined }) {
	return (
		<Switch>
			<Match when={props.product === 'TROON_ACCESS'}>
				<Tag appearance="info">Access</Tag>
			</Match>
			<Match when={props.product === 'TROON_ACCESS_PLUS'}>
				<Tag appearance="access">Access+</Tag>
			</Match>
		</Switch>
	);
}
